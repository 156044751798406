import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import SmallHeroSection from "../components/SmallHeroSection"
import DecorativeSection from "../components/DecorativeSection"
import { BsClock, BsEnvelope, BsGeoAlt, BsPhone } from "react-icons/bs"
import { Formik } from 'formik';
import axios from 'axios'

const ContactPage = () => (
  <Layout page="kontakt">
    <Seo
      title="Kontakt"
      description="Skontaktuj się z nami, jeśli masz jakieś pytania. Pomożemy wybrać najlepszą opcje uwzględniając wszytskie preferencje."
    />

    <SmallHeroSection />
    <DecorativeSection />
    <section id="contact" className="contact">
      <div className="container">
        <div className="section-title">
          <h2><span>Kontakt</span></h2>
          <p>Zadzwoń, napisz, bądźmy w kontakcie!</p>
            {/* <p className="text-center" style="color: darkred; text-transform:uppercase; font-weight: bold">
                PRZERWA ŚWIĄTECZNA 23.12 - 02.01
            </p> */}
        </div>
      </div>

      <div className="container map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2564.255165699039!2d20.971914316277875!3d50.00657242712828!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473d84afb635e68f%3A0xe2ae32bea3874d70!2sBarwy%20Zdrowia%20CATERING%20DIETETYCZNY!5e0!3m2!1spl!2spl!4v1642799082681!5m2!1spl!2spl" width="100%" height="350" frameBorder="0" allowFullScreen loading="lazy"></iframe>
      </div>

      <div className="container mt-5">
        <div className="info-wrap">
          <div className="row">
            <div className="col-lg-3 col-md-6 info  mt-4 mt-lg-0">
              <i><BsGeoAlt /></i>
              <h4>Lokalizacja:</h4>
              <p>ul. Dworcowa 3<br />33-100 Tarnów</p>
            </div>

            <div className="col-lg-3 col-md-6 info mt-4 mt-lg-0">
              <i><BsClock /></i>
              <h4>Godziny otwarcia:</h4>
              <p>Poniedziałek-Piątek:<br />07:00 - 15:00</p>
              <p>Sobota-Niedziela:<br />Nieczynne</p>
            </div>

            <div className="col-lg-3 col-md-6 info mt-4 mt-lg-0">
              <i><BsEnvelope /></i>
              <h4>Email:</h4>
              <p>kontakt@barwy-zdrowia.pl</p>
            </div>

            <div className="col-lg-3 col-md-6 info mt-4 mt-lg-0">
              <i><BsPhone /></i>
              
              <h4>Telefon:</h4>
              <p>07:00 - 15:00 <br></br><a href="tel:+48721399415">721 399 415</a></p>
              <p>Do kuriera <br></br><a href="tel:+48781062890">781 062 890</a><br></br>
              <a href="tel:+48693393979">693 393 979</a></p>
          </div>
        </div>
        </div>

        <Formik
          initialValues={{ name: '', subject: '', email: '', message: '' }}
          validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Pole jest wymagane';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Niepoprawny adres email';
            }
            if (!values.name) {
              errors.name = 'Pole jest wymagane';
            }
            if (!values.subject) {
              errors.subject = 'Pole jest wymagane';
            }
            if (!values.message) {
              errors.message = 'Pole jest wymagane';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            axios.post("/contact.php", values)
              .then((res) => {
                // console.log("Respoinse: ", res);
                setSubmitting(false);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row mt-3">
                <div className="col-md-6 form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Imię"
                    className="form-control"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />

                  <div className="text-danger">
                    {errors.name && touched.name && errors.name}
                  </div>
                </div>

                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Email"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email} />
                  <div className="text-danger">
                    {errors.email && touched.email && errors.email}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 form-group mt-3">
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    placeholder="Temat"
                    className="form-control"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subject}
                  />
                  <div className="text-danger">
                    {errors.subject && touched.subject && errors.subject}
                  </div>
                </div>

                <div className="col-md-12 form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    placeholder="Wiadomość"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                  ></textarea>
                  <div className="error-message">
                    {errors.message && touched.message && errors.message}
                  </div>
                </div>
              </div>


              <div className="my-3">
                {/* <div className="loading">Loading</div> */}
                {isSubmitting &&
                  <div className="text-success">Twoja wiadomość została wysłana. Dziękujemy!</div>
                }
              </div>
              <div className="text-center"><button data-aos="fade-up" data-aos-easing="ease" className="btn-main" type="submit" disabled={isSubmitting}>Wyślij wiadomość</button></div>
            </form>
          )}
        </Formik>
      </div>
    </section>

  </Layout>
)

export default ContactPage
